import { types } from '../actions/centers';

const defaultState = {
  centers: [],
  activeCenter: '',
};

const centersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_CENTERS:
      return { ...state, centers: [...action.payload.centers] };
    default:
      return state;
  }
};

export default centersReducer;
