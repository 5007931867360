import { types } from '../actions/payment';

const defaultState = {
  payment: {
    webRef: '',
    orderId: '',
    result: null,
    status: null,
  },
  paymentLoading: true,
  paymentSuccess: null,
};

const paymentReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.RECEIVE_PAYMENT_STATUS:
      return {
        ...state,
        payment: {
          webRef: action.payload.webRef,
          orderId: action.payload.orderId,
          result: action.payload.result,
          status: action.payload.status,
        },
        paymentLoading: false,
        paymentSuccess: true,
      };
    case types.PAYMENT_FAILURE:
      return {
        ...state,
        paymentLoading: false,
        paymentSuccess: false,
      };
    default:
      return state;
  }
};

export default paymentReducer;
