import { types } from '../actions/customizeProduct';

const defaultState = {
  selectedVariant: null,
  productVariants: [],
};

const customizeProductReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_VARIANT:
      return { ...state, selectedVariant: action.payload.nid };
    case types.SET_PRODUCT_VARIANTS:
      return { ...state, productVariants: action.payload.variants };
    default:
      return state;
  }
};

export default customizeProductReducer;
