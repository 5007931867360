export const types = {
  TOGGLE_FILTERS_VISIBILITY: 'TOGGLE_FILTERS_VISIBILITY',
  SET_OFFER_LISTINGS: 'SET_OFFER_LISTINGS',
};

export const toggleFilters = () => ({
  type: types.TOGGLE_FILTERS_VISIBILITY,
});

export const setOfferListings = (offers) => ({
  type: types.SET_OFFER_LISTINGS,
  payload: { offers },
});
