// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aquao-login-js": () => import("./../../../src/pages/aquao-login.js" /* webpackChunkName: "component---src-pages-aquao-login-js" */),
  "component---src-pages-centers-js": () => import("./../../../src/pages/centers.js" /* webpackChunkName: "component---src-pages-centers-js" */),
  "component---src-pages-change-password-js": () => import("./../../../src/pages/change-password.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-lyon-js": () => import("./../../../src/pages/lyon.js" /* webpackChunkName: "component---src-pages-lyon-js" */),
  "component---src-pages-mon-compte-js": () => import("./../../../src/pages/mon-compte.js" /* webpackChunkName: "component---src-pages-mon-compte-js" */),
  "component---src-pages-offers-js": () => import("./../../../src/pages/offers.js" /* webpackChunkName: "component---src-pages-offers-js" */),
  "component---src-pages-pages-js": () => import("./../../../src/pages/pages.js" /* webpackChunkName: "component---src-pages-pages-js" */),
  "component---src-pages-panier-js": () => import("./../../../src/pages/panier.js" /* webpackChunkName: "component---src-pages-panier-js" */),
  "component---src-pages-produit-js": () => import("./../../../src/pages/produit.js" /* webpackChunkName: "component---src-pages-produit-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-utiliser-un-bon-cadeau-js": () => import("./../../../src/pages/utiliser-un-bon-cadeau.js" /* webpackChunkName: "component---src-pages-utiliser-un-bon-cadeau-js" */)
}

