import { combineReducers } from 'redux';

import centersReducer from './reducers/centers';
import customizeProductReducer from './reducers/customizeProduct';
import offersReducer from './reducers/offers';
import authReducer from './reducers/auth';
import cartReducer from './reducers/cart';
import paymentReducer from './reducers/payment';

const rootReducer = combineReducers({
  centersReducer,
  customizeProduct: customizeProductReducer,
  offers: offersReducer,
  auth: authReducer,
  cart: cartReducer,
  checkout: paymentReducer,
});

export default rootReducer;
