export const types = {
  SET_ACTIVE_VARIANT: 'SET_ACTIVE_VARIANT',
  CHANGE_SELECTED_VARIANT: 'CHANGE_SELECTED_VARIANT',
  SET_PRODUCT_VARIANTS: 'SET_PRODUCT_VARIANTS',
};

export const setActiveVariant = (nid) => ({
  type: types.SET_ACTIVE_VARIANT,
  payload: { nid },
});

export const setProductVariants = (variants) => ({
  type: types.SET_PRODUCT_VARIANTS,
  payload: { variants },
});

export const changeSelectedVariant = (variant) => ({
  type: types.CHANGE_SELECTED_VARIANT,
  payload: { variant },
});
