export const types = {
  GET_CENTERS: 'GET_CENTERS',
  SET_CENTERS: 'SET_CENTERS',
};

export const setCenters = (centers) => ({
  type: types.SET_CENTERS,
  payload: { centers },
});

// export const setProductVariants = (variants) => ({
//   type: types.GET_CENTERS,
//   payload: { variants },
// });

// export const changeSelectedVariant = (variant) => ({
//   type: types.CHANGE_SELECTED_VARIANT,
//   payload: { variant },
// });
