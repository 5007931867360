import { types } from '../actions/offers';

const defaultState = {
  filtersVisible: false,
  listedOffers: [],
};

const offersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.TOGGLE_FILTERS_VISIBILITY:
      return { ...state, filtersVisible: !state.filtersVisible };
    case types.SET_OFFER_LISTINGS:
      return { ...state, listedOffers: action.payload.offers };
    default:
      return state;
  }
};

export default offersReducer;
