import { types } from '../actions/cart';

const defaultState = {
  details: {
    Products: [],
  },
  giftProducts: [],
  coffretProducts: [],
  codesCadeau: [],
  lastItemUrl: null,
};

const cartReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_CART:
      return {
        ...state,
        details: action.payload.cart,
      };
    case types.SET_PRODUCT_AS_GIFT:
      if (state.giftProducts.some(
        (giftProduct) => giftProduct.uid === action.payload.product.uid,
      )) {
        return state;
      }
      return {
        ...state,
        giftProducts: [
          ...state.giftProducts, action.payload.product],
      };
    case types.REMOVE_PRODUCT_AS_GIFT:
      return {
        ...state,
        giftProducts: state.giftProducts.filter(
          (giftProduct) => giftProduct.uid !== action.payload.product.uid,
        ),
      };
    case types.SET_PRODUCT_AS_COFFRET:
      if (state.coffretProducts.some(
        (coffretProducts) => coffretProducts.uid === action.payload.product.uid,
      )) {
        return state;
      }
      return {
        ...state,
        coffretProducts: [
          ...state.coffretProducts, action.payload.product],
      };
    case types.REMOVE_PRODUCT_AS_COFFRET:
      return {
        ...state,
        coffretProducts: state.coffretProducts.filter(
          (coffretProducts) => coffretProducts.uid !== action.payload.product.uid,
        ),
      };
    case types.SET_CODE_CADEAU:
      if (state.codesCadeau.some(
        (code) => code === action.payload.code,
      )) {
        return state;
      }
      return {
        ...state,
        codesCadeau: [
          ...state.codesCadeau, action.payload.code],
      };
    case types.REMOVE_CODE_CADEAU:
      return {
        ...state,
        codesCadeau: state.codesCadeau.filter(
          (code) => code !== action.payload.code,
        ),
      };
    case types.REMOVE_ALL_GIFT_PRODUCTS:
      return {
        ...state,
        giftProducts: [],
      };
    case types.ADD_LAST_ITEM_URL:
      return {
        ...state,
        lastItemUrl: action.payload.url,
      };
    case types.REMOVE_LAST_ITEM_URL:
      return {
        ...state,
        lastItemUrl: null,
      };
    default:
      return state;
  }
};

export default cartReducer;
