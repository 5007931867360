import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { Provider } from 'react-redux';
import store from './redux/store';
import { client } from './apollo/client';

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      {element}
    </Provider>
  </ApolloProvider>
);
