import {
  call, put, take, delay,
} from 'redux-saga/effects';

import {
  types,
  receivePaymentStatus,
  paymentFailure,
} from 'redux/actions/payment';

import API from 'api';

const requestPaymentCall = () => API.get(`/paiement/result?PaymentCode=${localStorage.getItem('webRef')}`);

function * updateApi() {
  for (let i = 0; i < 20; i++) {
    try {
      const apiResponse = yield call(requestPaymentCall);
      if (apiResponse.data.responseData.result === 'pending') {
        throw new Error('There is no result, retry');
      }
      localStorage.setItem('webRef', apiResponse.data.responseData.PaymentCode);
      return apiResponse;
    } catch (err) {
      if (i < 19) {
        yield delay(15000);
      }
    }
  }

  localStorage.setItem('redirectToFront', true);
  //throw new Error('API request failed');
}

export default function * updateResource() {
  while (true) {
    yield take(types.PAYMENT_STATUS_START);
    try {
      const apiResponse = yield call(updateApi);
      yield put(receivePaymentStatus(apiResponse));
    } catch (error) {
      yield put(paymentFailure());
    }
  }
}
