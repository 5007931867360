export const centreOffersBlockIds = {
  'home-group': '3',
  'centre-lyon': '14',
  'centre-bordeaux': '17',
  'centre-nantes': '19',
  'centre-pau': '20',
  'centre-perpignan': '21',
  'centre-toulouse': '18',
  'centre-saintcyrlecole': '84',
  'centre-lieusaint':'88',
};

export const centreEventsBlockIds = {
  'centre-lyon': '12',
  'centre-bordeaux': '22',
  'centre-nantes': '25',
  'centre-pau': '24',
  'centre-perpignan': '23',
  'centre-toulouse': '26',
  'centre-saintcyrlecole': '82',
  'centre-lieusaint':'90',
};

export const centreSimplePushBlock = {
  'home-group': '6',
  'centre-lyon': '47',
  'centre-bordeaux': '43',
  'centre-nantes': '45',
  'centre-pau': '46',
  'centre-perpignan': '48',
  'centre-toulouse': '44',
  'centre-saintcyrlecole': '86',
  'centre-lieusaint':'91',
};

export const centreComplexPushBlock = {
  'home-group': '7',
  'centre-lyon': '53',
  'centre-bordeaux': '49',
  'centre-nantes': '51',
  'centre-pau': '52',
  'centre-perpignan': '54',
  'centre-toulouse': '50',
  'centre-saintcyrlecole': '85',
  'centre-lieusaint':'93',
};

export const centerCookieIds = {
  'centre-lyon': {
    cid: '5',
    cookieName: 'Lyon_Cookie',
    phoneNumber: '0826 303 669',
    googleSiteVerification: 'HScuYeSDDPY-LLrt7fFlKzkLt1ReKhimeuTXTlis9Mw',
  },
  'centre-bordeaux': {
    cid: '1',
    cookieName: 'Bordeaux_Cookie',
    phoneNumber: '0826 30 32 33',
    googleSiteVerification: 'HScuYeSDDPY-LLrt7fFlKzkLt1ReKhimeuTXTlis9Mw',
  },
  'centre-nantes': {
    cid: '3',
    cookieName: 'Nantes_Cookie',
    phoneNumber: '0826 303 644',
    googleSiteVerification: 'HScuYeSDDPY-LLrt7fFlKzkLt1ReKhimeuTXTlis9Mw',
  },
  'centre-pau': {
    cid: '4',
    cookieName: 'Pau_Cookie',
    phoneNumber: '0826 303 664',
    googleSiteVerification: 'HScuYeSDDPY-LLrt7fFlKzkLt1ReKhimeuTXTlis9Mw',
  },
  'centre-perpignan': {
    cid: '6',
    cookieName: 'Perpignan_Cookie',
    phoneNumber: '0826 303 666',
    googleSiteVerification: 'HScuYeSDDPY-LLrt7fFlKzkLt1ReKhimeuTXTlis9Mw',
  },
  'centre-toulouse': {
    cid: '2',
    cookieName: 'Toulouse_Cookie',
    phoneNumber: '0826 303 305',
    googleSiteVerification: 'HScuYeSDDPY-LLrt7fFlKzkLt1ReKhimeuTXTlis9Mw',
  },
  'centre-saintcyrlecole': {
    cid: '7',
    cookieName: 'SaintCyr_Cookie',
    phoneNumber: '0826 303 669',
    googleSiteVerification: 'HScuYeSDDPY-LLrt7fFlKzkLt1ReKhimeuTXTlis9Mw',
  },
  'centre-cormeillesenparisis': {
    cid: '8',
    cookieName: 'CormeillesEnParisis_Cookie',
    phoneNumber: '0826 303 669',
    googleSiteVerification: 'HScuYeSDDPY-LLrt7fFlKzkLt1ReKhimeuTXTlis9Mw',
  },
  'centre-lieusaint': {
    cid: '9',
    cookieName: 'LieuSaint_Cookie',
    phoneNumber: '0826 303 669',
    googleSiteVerification: 'HScuYeSDDPY-LLrt7fFlKzkLt1ReKhimeuTXTlis9Mw',
  },
  'centre-caliceo': {
    cid: '0',
    googleSiteVerification: 'HScuYeSDDPY-LLrt7fFlKzkLt1ReKhimeuTXTlis9Mw',
  },
};

export const countriesList = [
  {
    oid: 1,
    libelle: 'FRANCE',
    libelleEnrichi: 'RÉPUBLIQUE FRANÇAISE',
    ancienNom: '',
    codeIso2: 'FR',
    codeIso3: 'FRA',
    codeNum3: '250',
  },
  {
    oid: 2,
    libelle: 'GUADELOUPE',
    libelleEnrichi: 'DÉPARTEMENT DE LA GUADELOUPE',
    ancienNom: '',
    codeIso2: 'GP',
    codeIso3: 'GLP',
    codeNum3: '312',
  },
  {
    oid: 3,
    libelle: 'GUYANE',
    libelleEnrichi: 'DÉPARTEMENT DE LA GUYANE',
    ancienNom: '',
    codeIso2: 'GF',
    codeIso3: 'GUF',
    codeNum3: '254',
  },
  {
    oid: 4,
    libelle: 'MARTINIQUE',
    libelleEnrichi: 'DÉPARTEMENT DE LA MARTINIQUE',
    ancienNom: '',
    codeIso2: 'MQ',
    codeIso3: 'MTQ',
    codeNum3: '474',
  },
  {
    oid: 5,
    libelle: 'NOUVELLE-CALEDONIE',
    libelleEnrichi: 'NOUVELLE-CALÉDONIE',
    ancienNom: '',
    codeIso2: 'NC',
    codeIso3: 'NCL',
    codeNum3: '540',
  },
  {
    oid: 6,
    libelle: 'POLYNESIE FRANCAISE',
    libelleEnrichi: 'POLYNÉSIE FRANÇAISE',
    ancienNom: '',
    codeIso2: 'PF',
    codeIso3: 'PYF',
    codeNum3: '258',
  },
  {
    oid: 7,
    libelle: 'CLIPPERTON (ILE)',
    libelleEnrichi: 'CLIPPERTON (ÎLE)',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 8,
    libelle: 'SAINT-PIERRE-ET-MIQUELON',
    libelleEnrichi: 'COLLECTIVITÉ TERRITORIALE DE SAINT-PIERRE-ET-MIQUELON',
    ancienNom: '',
    codeIso2: 'PM',
    codeIso3: 'SPM',
    codeNum3: '666',
  },
  {
    oid: 9,
    libelle: 'TERRES AUSTRALES FRANCAISES',
    libelleEnrichi: 'TERRES AUSTRALES FRANÇAISES',
    ancienNom: '',
    codeIso2: 'TF',
    codeIso3: 'ATF',
    codeNum3: '260',
  },
  {
    oid: 10,
    libelle: 'WALLIS-ET-FUTUNA',
    libelleEnrichi: 'WALLIS-ET-FUTUNA',
    ancienNom: '',
    codeIso2: 'WF',
    codeIso3: 'WLF',
    codeNum3: '876',
  },
  {
    oid: 11,
    libelle: 'MAYOTTE',
    libelleEnrichi: 'DÉPARTEMENT DE MAYOTTE',
    ancienNom: '',
    codeIso2: 'YT',
    codeIso3: 'MYT',
    codeNum3: '175',
  },
  {
    oid: 12,
    libelle: 'SAINT-BARTHELEMY',
    libelleEnrichi: "COLLECTIVITÉ D'OUTRE-MER DE SAINT-BARTHÉLEMY",
    ancienNom: '',
    codeIso2: 'BL',
    codeIso3: 'BLM',
    codeNum3: '652',
  },
  {
    oid: 13,
    libelle: 'SAINT-MARTIN',
    libelleEnrichi: "COLLECTIVITÉ D'OUTRE-MER DE SAINT-MARTIN",
    ancienNom: '',
    codeIso2: 'MF',
    codeIso3: 'MAF',
    codeNum3: '663',
  },
  {
    oid: 14,
    libelle: 'LA REUNION',
    libelleEnrichi: 'DÉPARTEMENT DE LA RÉUNION',
    ancienNom: '',
    codeIso2: 'RE',
    codeIso3: 'REU',
    codeNum3: '638',
  },
  {
    oid: 15,
    libelle: 'FEROE (ILES)',
    libelleEnrichi: 'FÉROÉ (ÎLES)',
    ancienNom: '',
    codeIso2: 'FO',
    codeIso3: 'FRO',
    codeNum3: '234',
  },
  {
    oid: 16,
    libelle: 'DANEMARK',
    libelleEnrichi: 'ROYAUME DU DANEMARK',
    ancienNom: '',
    codeIso2: 'DK',
    codeIso3: 'DNK',
    codeNum3: '208',
  },
  {
    oid: 17,
    libelle: 'ISLANDE',
    libelleEnrichi: "RÉPUBLIQUE D'ISLANDE",
    ancienNom: '',
    codeIso2: 'IS',
    codeIso3: 'ISL',
    codeNum3: '352',
  },
  {
    oid: 18,
    libelle: 'NORVEGE',
    libelleEnrichi: 'ROYAUME DE NORVÈGE',
    ancienNom: '',
    codeIso2: 'NO',
    codeIso3: 'NOR',
    codeNum3: '578',
  },
  {
    oid: 19,
    libelle: 'SVALBARD et ILE JAN MAYEN',
    libelleEnrichi: 'SVALBARD ET ÎLE JAN MAYEN',
    ancienNom: '',
    codeIso2: 'SJ',
    codeIso3: 'SJM',
    codeNum3: '744',
  },
  {
    oid: 20,
    libelle: 'BOUVET (ILE)',
    libelleEnrichi: 'BOUVET (ÎLE)',
    ancienNom: '',
    codeIso2: 'BV',
    codeIso3: 'BVT',
    codeNum3: '74',
  },
  {
    oid: 21,
    libelle: 'SUEDE',
    libelleEnrichi: 'ROYAUME DE SUÈDE',
    ancienNom: '',
    codeIso2: 'SE',
    codeIso3: 'SWE',
    codeNum3: '752',
  },
  {
    oid: 22,
    libelle: 'FINLANDE',
    libelleEnrichi: 'RÉPUBLIQUE DE FINLANDE',
    ancienNom: '',
    codeIso2: 'FI',
    codeIso3: 'FIN',
    codeNum3: '246',
  },
  {
    oid: 23,
    libelle: 'ESTONIE',
    libelleEnrichi: "RÉPUBLIQUE D'ESTONIE",
    ancienNom: '',
    codeIso2: 'EE',
    codeIso3: 'EST',
    codeNum3: '233',
  },
  {
    oid: 24,
    libelle: 'LETTONIE',
    libelleEnrichi: 'RÉPUBLIQUE DE LETTONIE',
    ancienNom: '',
    codeIso2: 'LV',
    codeIso3: 'LVA',
    codeNum3: '428',
  },
  {
    oid: 25,
    libelle: 'LITUANIE',
    libelleEnrichi: 'RÉPUBLIQUE DE LITUANIE',
    ancienNom: '',
    codeIso2: 'LT',
    codeIso3: 'LTU',
    codeNum3: '440',
  },
  {
    oid: 26,
    libelle: 'ALLEMAGNE',
    libelleEnrichi: "RÉPUBLIQUE FÉDÉRALE D'ALLEMAGNE",
    ancienNom: '',
    codeIso2: 'DE',
    codeIso3: 'DEU',
    codeNum3: '276',
  },
  {
    oid: 27,
    libelle: 'AUTRICHE',
    libelleEnrichi: "RÉPUBLIQUE D'AUTRICHE",
    ancienNom: '',
    codeIso2: 'AT',
    codeIso3: 'AUT',
    codeNum3: '40',
  },
  {
    oid: 28,
    libelle: 'BULGARIE',
    libelleEnrichi: 'RÉPUBLIQUE DE BULGARIE',
    ancienNom: '',
    codeIso2: 'BG',
    codeIso3: 'BGR',
    codeNum3: '100',
  },
  {
    oid: 29,
    libelle: 'HONGRIE',
    libelleEnrichi: 'RÉPUBLIQUE DE HONGRIE',
    ancienNom: '',
    codeIso2: 'HU',
    codeIso3: 'HUN',
    codeNum3: '348',
  },
  {
    oid: 30,
    libelle: 'LIECHTENSTEIN',
    libelleEnrichi: 'PRINCIPAUTÉ DE LIECHTENSTEIN',
    ancienNom: '',
    codeIso2: 'LI',
    codeIso3: 'LIE',
    codeNum3: '438',
  },
  {
    oid: 31,
    libelle: 'ROUMANIE',
    libelleEnrichi: 'ROUMANIE',
    ancienNom: '',
    codeIso2: 'RO',
    codeIso3: 'ROU',
    codeNum3: '642',
  },
  {
    oid: 32,
    libelle: 'TCHECOSLOVAQUIE',
    libelleEnrichi: 'TCHÉCOSLOVAQUIE',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 33,
    libelle: 'TCHEQUE (REPUBLIQUE)',
    libelleEnrichi: 'RÉPUBLIQUE TCHÈQUE',
    ancienNom: '',
    codeIso2: 'CZ',
    codeIso3: 'CZE',
    codeNum3: '203',
  },
  {
    oid: 34,
    libelle: 'SLOVAQUIE',
    libelleEnrichi: 'RÉPUBLIQUE SLOVAQUE',
    ancienNom: '',
    codeIso2: 'SK',
    codeIso3: 'SVK',
    codeNum3: '703',
  },
  {
    oid: 35,
    libelle: 'BOSNIE-HERZEGOVINE',
    libelleEnrichi: 'RÉPUBLIQUE DE BOSNIE-HERZÉGOVINE',
    ancienNom: '',
    codeIso2: 'BA',
    codeIso3: 'BIH',
    codeNum3: '70',
  },
  {
    oid: 36,
    libelle: 'CROATIE',
    libelleEnrichi: 'RÉPUBLIQUE DE CROATIE',
    ancienNom: '',
    codeIso2: 'HR',
    codeIso3: 'HRV',
    codeNum3: '191',
  },
  {
    oid: 37,
    libelle: 'MONTENEGRO',
    libelleEnrichi: 'RÉPUBLIQUE DU MONTÉNÉGRO',
    ancienNom: 'SERBIE-ET-MONTENEGRO',
    codeIso2: 'ME',
    codeIso3: 'MNE',
    codeNum3: '499',
  },
  {
    oid: 38,
    libelle: 'SERBIE',
    libelleEnrichi: 'RÉPUBLIQUE DE SERBIE',
    ancienNom: 'SERBIE-ET-MONTENEGRO',
    codeIso2: 'RS',
    codeIso3: 'SRB',
    codeNum3: '688',
  },
  {
    oid: 39,
    libelle: 'POLOGNE',
    libelleEnrichi: 'RÉPUBLIQUE DE POLOGNE',
    ancienNom: '',
    codeIso2: 'PL',
    codeIso3: 'POL',
    codeNum3: '616',
  },
  {
    oid: 40,
    libelle: 'RUSSIE',
    libelleEnrichi: 'FÉDÉRATION DE RUSSIE',
    ancienNom: 'U.R.S.S.',
    codeIso2: 'RU',
    codeIso3: 'RUS',
    codeNum3: '643',
  },
  {
    oid: 41,
    libelle: "TURQUIE D'EUROPE",
    libelleEnrichi: "TURQUIE D'EUROPE",
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 42,
    libelle: 'ALBANIE',
    libelleEnrichi: "RÉPUBLIQUE D'ALBANIE",
    ancienNom: '',
    codeIso2: 'AL',
    codeIso3: 'ALB',
    codeNum3: '8',
  },
  {
    oid: 43,
    libelle: 'GRECE',
    libelleEnrichi: 'RÉPUBLIQUE HELLÉNIQUE',
    ancienNom: '',
    codeIso2: 'GR',
    codeIso3: 'GRC',
    codeNum3: '300',
  },
  {
    oid: 44,
    libelle: 'ITALIE',
    libelleEnrichi: 'RÉPUBLIQUE ITALIENNE',
    ancienNom: '',
    codeIso2: 'IT',
    codeIso3: 'ITA',
    codeNum3: '380',
  },
  {
    oid: 45,
    libelle: 'SAINT-MARIN',
    libelleEnrichi: 'RÉPUBLIQUE DE SAINT-MARIN',
    ancienNom: '',
    codeIso2: 'SM',
    codeIso3: 'SMR',
    codeNum3: '674',
  },
  {
    oid: 46,
    libelle: 'VATICAN, ou SAINT-SIEGE',
    libelleEnrichi: 'ÉTAT DE LA CITÉ DU VATICAN',
    ancienNom: '',
    codeIso2: 'VA',
    codeIso3: 'VAT',
    codeNum3: '336',
  },
  {
    oid: 47,
    libelle: 'ANDORRE',
    libelleEnrichi: "PRINCIPAUTÉ D'ANDORRE",
    ancienNom: '',
    codeIso2: 'AD',
    codeIso3: 'AND',
    codeNum3: '20',
  },
  {
    oid: 48,
    libelle: 'BELGIQUE',
    libelleEnrichi: 'ROYAUME DE BELGIQUE',
    ancienNom: '',
    codeIso2: 'BE',
    codeIso3: 'BEL',
    codeNum3: '56',
  },
  {
    oid: 49,
    libelle: 'GUERNESEY',
    libelleEnrichi: 'GUERNESEY',
    ancienNom: '',
    codeIso2: 'GG',
    codeIso3: 'GGY',
    codeNum3: '831',
  },
  {
    oid: 50,
    libelle: 'MAN (ILE)',
    libelleEnrichi: 'MAN (ÎLE)',
    ancienNom: '',
    codeIso2: 'IM',
    codeIso3: 'IMN',
    codeNum3: '833',
  },
  {
    oid: 51,
    libelle: 'ROYAUME-UNI',
    libelleEnrichi: "ROYAUME-UNI DE GRANDE-BRETAGNE ET D'IRLANDE DU NORD",
    ancienNom: '',
    codeIso2: 'GB',
    codeIso3: 'GBR',
    codeNum3: '826',
  },
  {
    oid: 52,
    libelle: 'JERSEY',
    libelleEnrichi: 'JERSEY',
    ancienNom: '',
    codeIso2: 'JE',
    codeIso3: 'JEY',
    codeNum3: '832',
  },
  {
    oid: 53,
    libelle: 'GIBRALTAR',
    libelleEnrichi: 'GIBRALTAR',
    ancienNom: '',
    codeIso2: 'GI',
    codeIso3: 'GIB',
    codeNum3: '292',
  },
  {
    oid: 54,
    libelle: 'ESPAGNE',
    libelleEnrichi: "ROYAUME D'ESPAGNE",
    ancienNom: '',
    codeIso2: 'ES',
    codeIso3: 'ESP',
    codeNum3: '724',
  },
  {
    oid: 55,
    libelle: 'ARUBA',
    libelleEnrichi: 'ARUBA',
    ancienNom: '',
    codeIso2: 'AW',
    codeIso3: 'ABW',
    codeNum3: '533',
  },
  {
    oid: 56,
    libelle: 'PAYS-BAS',
    libelleEnrichi: 'ROYAUME DES PAYS-BAS',
    ancienNom: 'HOLLANDE',
    codeIso2: 'NL',
    codeIso3: 'NLD',
    codeNum3: '528',
  },
  {
    oid: 57,
    libelle: 'IRLANDE, ou EIRE',
    libelleEnrichi: 'IRLANDE',
    ancienNom: '',
    codeIso2: 'IE',
    codeIso3: 'IRL',
    codeNum3: '372',
  },
  {
    oid: 58,
    libelle: 'LUXEMBOURG',
    libelleEnrichi: 'GRAND-DUCHÉ DE LUXEMBOURG',
    ancienNom: '',
    codeIso2: 'LU',
    codeIso3: 'LUX',
    codeNum3: '442',
  },
  {
    oid: 59,
    libelle: 'MONACO',
    libelleEnrichi: 'PRINCIPAUTÉ DE MONACO',
    ancienNom: '',
    codeIso2: 'MC',
    codeIso3: 'MCO',
    codeNum3: '492',
  },
  {
    oid: 60,
    libelle: 'PORTUGAL',
    libelleEnrichi: 'RÉPUBLIQUE PORTUGAISE',
    ancienNom: '',
    codeIso2: 'PT',
    codeIso3: 'PRT',
    codeNum3: '620',
  },
  {
    oid: 61,
    libelle: 'SUISSE',
    libelleEnrichi: 'CONFÉDÉRATION SUISSE',
    ancienNom: '',
    codeIso2: 'CH',
    codeIso3: 'CHE',
    codeNum3: '756',
  },
  {
    oid: 62,
    libelle: 'REPUBLIQUE DEMOCRATIQUE ALLEMANDE',
    libelleEnrichi: 'RÉPUBLIQUE DÉMOCRATIQUE ALLEMANDE',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 63,
    libelle: "REPUBLIQUE FEDERALE D'ALLEMAGNE",
    libelleEnrichi: "RÉPUBLIQUE FÉDÉRALE D'ALLEMAGNE",
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 64,
    libelle: 'MALTE',
    libelleEnrichi: 'RÉPUBLIQUE DE MALTE',
    ancienNom: '',
    codeIso2: 'MT',
    codeIso3: 'MLT',
    codeNum3: '470',
  },
  {
    oid: 65,
    libelle: 'SLOVENIE',
    libelleEnrichi: 'RÉPUBLIQUE DE SLOVÉNIE',
    ancienNom: '',
    codeIso2: 'SI',
    codeIso3: 'SVN',
    codeNum3: '705',
  },
  {
    oid: 66,
    libelle: 'BIELORUSSIE',
    libelleEnrichi: 'RÉPUBLIQUE DE BIÉLORUSSIE',
    ancienNom: '',
    codeIso2: 'BY',
    codeIso3: 'BLR',
    codeNum3: '112',
  },
  {
    oid: 67,
    libelle: 'MOLDAVIE',
    libelleEnrichi: 'RÉPUBLIQUE DE MOLDAVIE',
    ancienNom: '',
    codeIso2: 'MD',
    codeIso3: 'MDA',
    codeNum3: '498',
  },
  {
    oid: 68,
    libelle: 'UKRAINE',
    libelleEnrichi: 'UKRAINE',
    ancienNom: '',
    codeIso2: 'UA',
    codeIso3: 'UKR',
    codeNum3: '804',
  },
  {
    oid: 69,
    libelle: 'EX-REPUBLIQUE YOUGOSLAVE DE MACEDOINE',
    libelleEnrichi: 'EX-RÉPUBLIQUE YOUGOSLAVE DE MACÉDOINE',
    ancienNom: '',
    codeIso2: 'MK',
    codeIso3: 'MKD',
    codeNum3: '807',
  },
  {
    oid: 70,
    libelle: 'KOSOVO',
    libelleEnrichi: 'RÉPUBLIQUE DU KOSOVO',
    ancienNom: 'SERBIE',
    codeIso2: 'XK',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 71,
    libelle: 'ARABIE SAOUDITE',
    libelleEnrichi: "ROYAUME D'ARABIE SAOUDITE",
    ancienNom: '',
    codeIso2: 'SA',
    codeIso3: 'SAU',
    codeNum3: '682',
  },
  {
    oid: 72,
    libelle: 'YEMEN (REPUBLIQUE ARABE DU)',
    libelleEnrichi: 'YÉMEN (RÉPUBLIQUE ARABE DU)',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 73,
    libelle: 'IRAQ',
    libelleEnrichi: "RÉPUBLIQUE D'IRAQ",
    ancienNom: '',
    codeIso2: 'IQ',
    codeIso3: 'IRQ',
    codeNum3: '368',
  },
  {
    oid: 74,
    libelle: 'IRAN',
    libelleEnrichi: "RÉPUBLIQUE ISLAMIQUE D'IRAN",
    ancienNom: 'PERSE',
    codeIso2: 'IR',
    codeIso3: 'IRN',
    codeNum3: '364',
  },
  {
    oid: 75,
    libelle: 'LIBAN',
    libelleEnrichi: 'RÉPUBLIQUE LIBANAISE',
    ancienNom: '',
    codeIso2: 'LB',
    codeIso3: 'LBN',
    codeNum3: '422',
  },
  {
    oid: 76,
    libelle: 'SYRIE',
    libelleEnrichi: 'RÉPUBLIQUE ARABE SYRIENNE',
    ancienNom: '',
    codeIso2: 'SY',
    codeIso3: 'SYR',
    codeNum3: '760',
  },
  {
    oid: 77,
    libelle: 'ISRAEL',
    libelleEnrichi: "ÉTAT D'ISRAËL",
    ancienNom: '',
    codeIso2: 'IL',
    codeIso3: 'ISR',
    codeNum3: '376',
  },
  {
    oid: 78,
    libelle: 'TURQUIE',
    libelleEnrichi: 'RÉPUBLIQUE TURQUE',
    ancienNom: '',
    codeIso2: 'TR',
    codeIso3: 'TUR',
    codeNum3: '792',
  },
  {
    oid: 79,
    libelle: 'SIBERIE',
    libelleEnrichi: 'SIBÉRIE',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 80,
    libelle: 'TURKESTAN RUSSE',
    libelleEnrichi: 'TURKESTAN RUSSE',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 81,
    libelle: 'KAMTCHATKA',
    libelleEnrichi: 'KAMTCHATKA',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 82,
    libelle: 'AFGHANISTAN',
    libelleEnrichi: "ÉTAT ISLAMIQUE D'AFGHANISTAN",
    ancienNom: '',
    codeIso2: 'AF',
    codeIso3: 'AFG',
    codeNum3: '4',
  },
  {
    oid: 83,
    libelle: 'PAKISTAN',
    libelleEnrichi: 'RÉPUBLIQUE ISLAMIQUE DU PAKISTAN',
    ancienNom: '',
    codeIso2: 'PK',
    codeIso3: 'PAK',
    codeNum3: '586',
  },
  {
    oid: 84,
    libelle: 'BHOUTAN',
    libelleEnrichi: 'ROYAUME DU BHOUTAN',
    ancienNom: '',
    codeIso2: 'BT',
    codeIso3: 'BTN',
    codeNum3: '64',
  },
  {
    oid: 85,
    libelle: 'NEPAL',
    libelleEnrichi: 'ROYAUME DU NÉPAL',
    ancienNom: '',
    codeIso2: 'NP',
    codeIso3: 'NPL',
    codeNum3: '524',
  },
  {
    oid: 86,
    libelle: 'CHINE',
    libelleEnrichi: 'RÉPUBLIQUE POPULAIRE DE CHINE',
    ancienNom: '',
    codeIso2: 'CN',
    codeIso3: 'CHN',
    codeNum3: '156',
  },
  {
    oid: 87,
    libelle: 'JAPON',
    libelleEnrichi: 'JAPON',
    ancienNom: '',
    codeIso2: 'JP',
    codeIso3: 'JPN',
    codeNum3: '392',
  },
  {
    oid: 88,
    libelle: 'MANDCHOURIE',
    libelleEnrichi: 'MANDCHOURIE',
    ancienNom: 'MANDCHOUKOUO',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 89,
    libelle: 'THAILANDE',
    libelleEnrichi: 'ROYAUME DE THAÝLANDE',
    ancienNom: 'SIAM',
    codeIso2: 'TH',
    codeIso3: 'THA',
    codeNum3: '764',
  },
  {
    oid: 90,
    libelle: 'PHILIPPINES',
    libelleEnrichi: 'RÉPUBLIQUE DES PHILIPPINES',
    ancienNom: '',
    codeIso2: 'PH',
    codeIso3: 'PHL',
    codeNum3: '608',
  },
  {
    oid: 91,
    libelle: 'POSSESSIONS BRITANNIQUES AU PROCHE-ORIENT',
    libelleEnrichi: 'POSSESSIONS BRITANNIQUES AU PROCHE-ORIENT',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 92,
    libelle: 'JORDANIE',
    libelleEnrichi: 'ROYAUME HACHÉMITE DE JORDANIE',
    ancienNom: 'TRANSJORDANIE',
    codeIso2: 'JO',
    codeIso3: 'JOR',
    codeNum3: '400',
  },
  {
    oid: 93,
    libelle: 'GOA',
    libelleEnrichi: 'GOA',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 94,
    libelle: 'INDE',
    libelleEnrichi: "RÉPUBLIQUE DE L'INDE",
    ancienNom: '',
    codeIso2: 'IN',
    codeIso3: 'IND',
    codeNum3: '356',
  },
  {
    oid: 95,
    libelle: 'BIRMANIE',
    libelleEnrichi: 'UNION DE BIRMANIE',
    ancienNom: '',
    codeIso2: 'MM',
    codeIso3: 'MMR',
    codeNum3: '104',
  },
  {
    oid: 96,
    libelle: 'BRUNEI',
    libelleEnrichi: 'NEGARA BRUNEI DARUSSALAM',
    ancienNom: 'BORNEO BRITANNIQUE',
    codeIso2: 'BN',
    codeIso3: 'BRN',
    codeNum3: '96',
  },
  {
    oid: 97,
    libelle: 'SINGAPOUR',
    libelleEnrichi: 'RÉPUBLIQUE DE SINGAPOUR',
    ancienNom: 'ETABLS. DES DETROITS',
    codeIso2: 'SG',
    codeIso3: 'SGP',
    codeNum3: '702',
  },
  {
    oid: 98,
    libelle: 'MALAISIE',
    libelleEnrichi: 'MALAISIE',
    ancienNom: '',
    codeIso2: 'MY',
    codeIso3: 'MYS',
    codeNum3: '458',
  },
  {
    oid: 99,
    libelle: 'ETATS MALAIS NON FEDERES',
    libelleEnrichi: 'ÉTATS MALAIS NON FÉDÉRÉS',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 100,
    libelle: 'MALDIVES',
    libelleEnrichi: 'RÉPUBLIQUE DES MALDIVES',
    ancienNom: 'ILES MALDIVES',
    codeIso2: 'MV',
    codeIso3: 'MDV',
    codeNum3: '462',
  },
  {
    oid: 101,
    libelle: 'HONG-KONG',
    libelleEnrichi: 'HONG-KONG',
    ancienNom: '',
    codeIso2: 'HK',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 102,
    libelle: 'INDONESIE',
    libelleEnrichi: "RÉPUBLIQUE D'INDONÉSIE",
    ancienNom: 'INDES NEER. ET PORT.',
    codeIso2: 'ID',
    codeIso3: 'IDN',
    codeNum3: '360',
  },
  {
    oid: 103,
    libelle: 'MACAO',
    libelleEnrichi: 'MACAO',
    ancienNom: '',
    codeIso2: 'MO',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 104,
    libelle: 'YEMEN DEMOCRATIQUE',
    libelleEnrichi: 'YÉMEN DÉMOCRATIQUE',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 105,
    libelle: 'CAMBODGE',
    libelleEnrichi: 'ROYAUME DU CAMBODGE',
    ancienNom: 'KAMPUCHEA DEM.',
    codeIso2: 'KH',
    codeIso3: 'KHM',
    codeNum3: '116',
  },
  {
    oid: 106,
    libelle: 'SRI LANKA',
    libelleEnrichi: 'RÉPUBLIQUE DÉMOCRATIQUE SOCIALISTE DU SRI LANKA',
    ancienNom: 'CEYLAN',
    codeIso2: 'LK',
    codeIso3: 'LKA',
    codeNum3: '144',
  },
  {
    oid: 107,
    libelle: 'TAIWAN',
    libelleEnrichi: 'TAÝWAN',
    ancienNom: 'FORMOSE',
    codeIso2: 'TW',
    codeIso3: 'TWN',
    codeNum3: '158',
  },
  {
    oid: 108,
    libelle: 'COREE',
    libelleEnrichi: 'CORÉE',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 109,
    libelle: 'COREE (REPUBLIQUE POPULAIRE DEMOCRATIQUE DE)',
    libelleEnrichi: 'RÉPUBLIQUE POPULAIRE DÉMOCRATIQUE DE CORÉE',
    ancienNom: '',
    codeIso2: 'KP',
    codeIso3: 'PRK',
    codeNum3: '408',
  },
  {
    oid: 110,
    libelle: 'COREE (REPUBLIQUE DE)',
    libelleEnrichi: 'RÉPUBLIQUE DE CORÉE',
    ancienNom: '',
    codeIso2: 'KR',
    codeIso3: 'KOR',
    codeNum3: '410',
  },
  {
    oid: 111,
    libelle: 'KOWEIT',
    libelleEnrichi: 'ÉTAT DU KOWEÝT',
    ancienNom: '',
    codeIso2: 'KW',
    codeIso3: 'KWT',
    codeNum3: '414',
  },
  {
    oid: 112,
    libelle: 'LAOS',
    libelleEnrichi: 'RÉPUBLIQUE DÉMOCRATIQUE POPULAIRE LAO',
    ancienNom: '',
    codeIso2: 'LA',
    codeIso3: 'LAO',
    codeNum3: '418',
  },
  {
    oid: 113,
    libelle: 'MONGOLIE',
    libelleEnrichi: 'MONGOLIE',
    ancienNom: 'MONGOLIE EXTERIEURE',
    codeIso2: 'MN',
    codeIso3: 'MNG',
    codeNum3: '496',
  },
  {
    oid: 114,
    libelle: 'VIET NAM',
    libelleEnrichi: 'RÉPUBLIQUE SOCIALISTE DU VIÊT NAM',
    ancienNom: '',
    codeIso2: 'VN',
    codeIso3: 'VNM',
    codeNum3: '704',
  },
  {
    oid: 115,
    libelle: 'VIET NAM DU NORD',
    libelleEnrichi: 'VIÊT NAM DU NORD',
    ancienNom: 'TONKIN, ANNAM N.',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 116,
    libelle: 'VIET NAM DU SUD',
    libelleEnrichi: 'VIÊT NAM DU SUD',
    ancienNom: 'ANNAM S.,COCHINCHINE',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 117,
    libelle: 'BANGLADESH',
    libelleEnrichi: 'RÉPUBLIQUE POPULAIRE DU BANGLADESH',
    ancienNom: 'PAKISTAN ORIENTAL',
    codeIso2: 'BD',
    codeIso3: 'BGD',
    codeNum3: '50',
  },
  {
    oid: 118,
    libelle: 'EMIRATS ARABES UNIS',
    libelleEnrichi: 'ÉMIRATS ARABES UNIS',
    ancienNom: '',
    codeIso2: 'AE',
    codeIso3: 'ARE',
    codeNum3: '784',
  },
  {
    oid: 119,
    libelle: 'QATAR',
    libelleEnrichi: 'ÉTAT DU QATAR',
    ancienNom: '',
    codeIso2: 'QA',
    codeIso3: 'QAT',
    codeNum3: '634',
  },
  {
    oid: 120,
    libelle: 'BAHREIN',
    libelleEnrichi: 'ÉTAT DE BAHREÝN',
    ancienNom: '',
    codeIso2: 'BH',
    codeIso3: 'BHR',
    codeNum3: '48',
  },
  {
    oid: 121,
    libelle: 'OMAN',
    libelleEnrichi: "SULTANAT D'OMAN",
    ancienNom: 'MASCATE-ET-OMAN',
    codeIso2: 'OM',
    codeIso3: 'OMN',
    codeNum3: '512',
  },
  {
    oid: 122,
    libelle: 'YEMEN',
    libelleEnrichi: 'RÉPUBLIQUE DU YÉMEN',
    ancienNom: '',
    codeIso2: 'YE',
    codeIso3: 'YEM',
    codeNum3: '887',
  },
  {
    oid: 123,
    libelle: 'ARMENIE',
    libelleEnrichi: "RÉPUBLIQUE D'ARMÉNIE",
    ancienNom: '',
    codeIso2: 'AM',
    codeIso3: 'ARM',
    codeNum3: '51',
  },
  {
    oid: 124,
    libelle: 'AZERBAIDJAN',
    libelleEnrichi: 'RÉPUBLIQUE AZERBAÝDJANAISE',
    ancienNom: '',
    codeIso2: 'AZ',
    codeIso3: 'AZE',
    codeNum3: '31',
  },
  {
    oid: 125,
    libelle: 'CHYPRE',
    libelleEnrichi: 'RÉPUBLIQUE DE CHYPRE',
    ancienNom: '',
    codeIso2: 'CY',
    codeIso3: 'CYP',
    codeNum3: '196',
  },
  {
    oid: 126,
    libelle: 'GEORGIE',
    libelleEnrichi: 'RÉPUBLIQUE DE GÉORGIE',
    ancienNom: '',
    codeIso2: 'GE',
    codeIso3: 'GEO',
    codeNum3: '268',
  },
  {
    oid: 127,
    libelle: 'KAZAKHSTAN',
    libelleEnrichi: 'RÉPUBLIQUE DU KAZAKHSTAN',
    ancienNom: '',
    codeIso2: 'KZ',
    codeIso3: 'KAZ',
    codeNum3: '398',
  },
  {
    oid: 128,
    libelle: 'KIRGHIZISTAN',
    libelleEnrichi: 'RÉPUBLIQUE KIRGHIZE',
    ancienNom: '',
    codeIso2: 'KG',
    codeIso3: 'KGZ',
    codeNum3: '417',
  },
  {
    oid: 129,
    libelle: 'OUZBEKISTAN',
    libelleEnrichi: "RÉPUBLIQUE D'OUZBÉKISTAN",
    ancienNom: '',
    codeIso2: 'UZ',
    codeIso3: 'UZB',
    codeNum3: '860',
  },
  {
    oid: 130,
    libelle: 'TADJIKISTAN',
    libelleEnrichi: 'RÉPUBLIQUE DU TADJIKISTAN',
    ancienNom: '',
    codeIso2: 'TJ',
    codeIso3: 'TJK',
    codeNum3: '762',
  },
  {
    oid: 131,
    libelle: 'TURKMENISTAN',
    libelleEnrichi: 'TURKMÉNISTAN',
    ancienNom: '',
    codeIso2: 'TM',
    codeIso3: 'TKM',
    codeNum3: '795',
  },
  {
    oid: 132,
    libelle: 'PALESTINE (Etat de)',
    libelleEnrichi: 'ÉTAT DE PALESTINE',
    ancienNom: 'PALESTINE (Territoire de)',
    codeIso2: 'PS',
    codeIso3: 'PSE',
    codeNum3: '275',
  },
  {
    oid: 133,
    libelle: 'TIMOR ORIENTAL',
    libelleEnrichi: 'RÉPUBLIQUE DÉMOCRATIQUE DU TIMOR ORIENTAL',
    ancienNom: '',
    codeIso2: 'TL',
    codeIso3: 'TLS',
    codeNum3: '626',
  },
  {
    oid: 134,
    libelle: 'EGYPTE',
    libelleEnrichi: "RÉPUBLIQUE ARABE D'ÉGYPTE",
    ancienNom: '',
    codeIso2: 'EG',
    codeIso3: 'EGY',
    codeNum3: '818',
  },
  {
    oid: 135,
    libelle: 'LIBERIA',
    libelleEnrichi: 'RÉPUBLIQUE DU LIBERIA',
    ancienNom: '',
    codeIso2: 'LR',
    codeIso3: 'LBR',
    codeNum3: '430',
  },
  {
    oid: 136,
    libelle: 'AFRIQUE DU SUD',
    libelleEnrichi: "RÉPUBLIQUE D'AFRIQUE DU SUD",
    ancienNom: 'UNION SUD-AFRICAINE',
    codeIso2: 'ZA',
    codeIso3: 'ZAF',
    codeNum3: '710',
  },
  {
    oid: 137,
    libelle: 'GAMBIE',
    libelleEnrichi: 'RÉPUBLIQUE DE GAMBIE',
    ancienNom: '',
    codeIso2: 'GM',
    codeIso3: 'GMB',
    codeNum3: '270',
  },
  {
    oid: 138,
    libelle: 'CAMEROUN ET TOGO',
    libelleEnrichi: 'CAMEROUN ET TOGO',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 139,
    libelle: 'SAINTE HELENE, ASCENSION ET TRISTAN DA CUNHA',
    libelleEnrichi: 'SAINTE-HÉLÈNE, ASCENSION ET TRISTAN DA CUNHA',
    ancienNom: 'SAINTE HELENE',
    codeIso2: 'SH',
    codeIso3: 'SHN',
    codeNum3: '654',
  },
  {
    oid: 140,
    libelle: 'SOUDAN ANGLO-EGYPTIEN, KENYA, OUGANDA',
    libelleEnrichi: 'SOUDAN ANGLO-ÉGYPTIEN, KENYA, OUGANDA',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 141,
    libelle: "OCEAN INDIEN (TERRITOIRE BRITANNIQUE DE L')",
    libelleEnrichi: "OCÉAN INDIEN (TERRITOIRE BRITANNIQUE DE L')",
    ancienNom: '',
    codeIso2: 'IO',
    codeIso3: 'IOT',
    codeNum3: '86',
  },
  {
    oid: 142,
    libelle: 'ZANZIBAR',
    libelleEnrichi: 'ZANZIBAR',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 143,
    libelle: 'TANZANIE',
    libelleEnrichi: 'RÉPUBLIQUE UNIE DE TANZANIE',
    ancienNom: 'TANGANYIKA, ZANZIBAR',
    codeIso2: 'TZ',
    codeIso3: 'TZA',
    codeNum3: '834',
  },
  {
    oid: 144,
    libelle: 'ZIMBABWE',
    libelleEnrichi: 'RÉPUBLIQUE DU ZIMBABWE',
    ancienNom: 'RHODESIE DU SUD',
    codeIso2: 'ZW',
    codeIso3: 'ZWE',
    codeNum3: '716',
  },
  {
    oid: 145,
    libelle: 'NAMIBIE',
    libelleEnrichi: 'RÉPUBLIQUE DE NAMIBIE',
    ancienNom: 'SUD-OUEST AFRICAIN',
    codeIso2: 'NA',
    codeIso3: 'NAM',
    codeNum3: '516',
  },
  {
    oid: 146,
    libelle: 'CONGO (REPUBLIQUE DEMOCRATIQUE)',
    libelleEnrichi: 'RÉPUBLIQUE DÉMOCRATIQUE DU CONGO',
    ancienNom: 'ZAIRE',
    codeIso2: 'CD',
    codeIso3: 'COD',
    codeNum3: '180',
  },
  {
    oid: 147,
    libelle: 'CANARIES (ILES)',
    libelleEnrichi: 'CANARIES (ÎLES)',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 148,
    libelle: 'PRESIDES',
    libelleEnrichi: 'PRÉSIDES',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 149,
    libelle: "PROVINCES ESPAGNOLES D'AFRIQUE",
    libelleEnrichi: "PROVINCES ESPAGNOLES D'AFRIQUE",
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 150,
    libelle: 'GUINEE EQUATORIALE',
    libelleEnrichi: 'RÉPUBLIQUE DE GUINÉE ÉQUATORIALE',
    ancienNom: 'GUINEE ESPAGNOLE',
    codeIso2: 'GQ',
    codeIso3: 'GNQ',
    codeNum3: '226',
  },
  {
    oid: 151,
    libelle: 'ETHIOPIE',
    libelleEnrichi: "RÉPUBLIQUE DÉMOCRATIQUE FÉDÉRALE D'ÉTHIOPIE",
    ancienNom: 'ABYSSINIE',
    codeIso2: 'ET',
    codeIso3: 'ETH',
    codeNum3: '231',
  },
  {
    oid: 152,
    libelle: 'LIBYE',
    libelleEnrichi: 'JAMAHIRIYA ARABE LIBYENNE POPULAIRE ET SOCIALISTE',
    ancienNom: 'CYRENAIQUE',
    codeIso2: 'LY',
    codeIso3: 'LBY',
    codeNum3: '434',
  },
  {
    oid: 153,
    libelle: 'ERYTHREE',
    libelleEnrichi: "ÉTAT D'ÉRYTHRÉE",
    ancienNom: '',
    codeIso2: 'ER',
    codeIso3: 'ERI',
    codeNum3: '232',
  },
  {
    oid: 154,
    libelle: 'SOMALIE',
    libelleEnrichi: 'SOMALIE',
    ancienNom: '',
    codeIso2: 'SO',
    codeIso3: 'SOM',
    codeNum3: '706',
  },
  {
    oid: 155,
    libelle: 'ACORES, MADERE',
    libelleEnrichi: 'AÇORES, MADÈRE',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 156,
    libelle: "ILES PORTUGAISES DE L'OCEAN INDIEN",
    libelleEnrichi: "ÎLES PORTUGAISES DE L'OCÉAN INDIEN",
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 157,
    libelle: 'BURUNDI',
    libelleEnrichi: 'RÉPUBLIQUE DU BURUNDI',
    ancienNom: 'URUNDI',
    codeIso2: 'BI',
    codeIso3: 'BDI',
    codeNum3: '108',
  },
  {
    oid: 158,
    libelle: 'CAMEROUN',
    libelleEnrichi: 'RÉPUBLIQUE DU CAMEROUN',
    ancienNom: '',
    codeIso2: 'CM',
    codeIso3: 'CMR',
    codeNum3: '120',
  },
  {
    oid: 159,
    libelle: 'CENTRAFRICAINE (REPUBLIQUE)',
    libelleEnrichi: 'RÉPUBLIQUE CENTRAFRICAINE',
    ancienNom: 'OUBANGUI-CHARI',
    codeIso2: 'CF',
    codeIso3: 'CAF',
    codeNum3: '140',
  },
  {
    oid: 160,
    libelle: 'CONGO',
    libelleEnrichi: 'RÉPUBLIQUE DU CONGO',
    ancienNom: 'CONGO-BRAZZAVILLE',
    codeIso2: 'CG',
    codeIso3: 'COG',
    codeNum3: '178',
  },
  {
    oid: 161,
    libelle: 'TANGER',
    libelleEnrichi: 'TANGER',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 162,
    libelle: "COTE D'IVOIRE",
    libelleEnrichi: "RÉPUBLIQUE DE CÔTE D'IVOIRE",
    ancienNom: '',
    codeIso2: 'CI',
    codeIso3: 'CIV',
    codeNum3: '384',
  },
  {
    oid: 163,
    libelle: 'BENIN',
    libelleEnrichi: 'RÉPUBLIQUE DU BÉNIN',
    ancienNom: 'DAHOMEY',
    codeIso2: 'BJ',
    codeIso3: 'BEN',
    codeNum3: '204',
  },
  {
    oid: 164,
    libelle: 'GABON',
    libelleEnrichi: 'RÉPUBLIQUE GABONAISE',
    ancienNom: '',
    codeIso2: 'GA',
    codeIso3: 'GAB',
    codeNum3: '266',
  },
  {
    oid: 165,
    libelle: 'GHANA',
    libelleEnrichi: 'RÉPUBLIQUE DU GHANA',
    ancienNom: "COTE-DE-L'OR",
    codeIso2: 'GH',
    codeIso3: 'GHA',
    codeNum3: '288',
  },
  {
    oid: 166,
    libelle: 'GUINEE',
    libelleEnrichi: 'RÉPUBLIQUE DE GUINÉE',
    ancienNom: 'GUINEE FRANCAISE',
    codeIso2: 'GN',
    codeIso3: 'GIN',
    codeNum3: '324',
  },
  {
    oid: 167,
    libelle: 'BURKINA',
    libelleEnrichi: 'BURKINA FASO',
    ancienNom: 'HAUTE-VOLTA',
    codeIso2: 'BF',
    codeIso3: 'BFA',
    codeNum3: '854',
  },
  {
    oid: 168,
    libelle: 'KENYA',
    libelleEnrichi: 'RÉPUBLIQUE DU KENYA',
    ancienNom: '',
    codeIso2: 'KE',
    codeIso3: 'KEN',
    codeNum3: '404',
  },
  {
    oid: 169,
    libelle: 'MADAGASCAR',
    libelleEnrichi: 'RÉPUBLIQUE DE MADAGASCAR',
    ancienNom: '',
    codeIso2: 'MG',
    codeIso3: 'MDG',
    codeNum3: '450',
  },
  {
    oid: 170,
    libelle: 'MALAWI',
    libelleEnrichi: 'RÉPUBLIQUE DU MALAWI',
    ancienNom: 'NYASSALAND',
    codeIso2: 'MW',
    codeIso3: 'MWI',
    codeNum3: '454',
  },
  {
    oid: 171,
    libelle: 'MALI',
    libelleEnrichi: 'RÉPUBLIQUE DU MALI',
    ancienNom: 'SOUDAN FRANCAIS',
    codeIso2: 'ML',
    codeIso3: 'MLI',
    codeNum3: '466',
  },
  {
    oid: 172,
    libelle: 'MAURITANIE',
    libelleEnrichi: 'RÉPUBLIQUE ISLAMIQUE DE MAURITANIE',
    ancienNom: '',
    codeIso2: 'MR',
    codeIso3: 'MRT',
    codeNum3: '478',
  },
  {
    oid: 173,
    libelle: 'NIGER',
    libelleEnrichi: 'RÉPUBLIQUE DU NIGER',
    ancienNom: '',
    codeIso2: 'NE',
    codeIso3: 'NER',
    codeNum3: '562',
  },
  {
    oid: 174,
    libelle: 'NIGERIA',
    libelleEnrichi: 'RÉPUBLIQUE FÉDÉRALE DU NIGÉRIA',
    ancienNom: '',
    codeIso2: 'NG',
    codeIso3: 'NGA',
    codeNum3: '566',
  },
  {
    oid: 175,
    libelle: 'OUGANDA',
    libelleEnrichi: "RÉPUBLIQUE DE L'OUGANDA",
    ancienNom: '',
    codeIso2: 'UG',
    codeIso3: 'UGA',
    codeNum3: '800',
  },
  {
    oid: 176,
    libelle: 'RWANDA',
    libelleEnrichi: 'RÉPUBLIQUE RWANDAISE',
    ancienNom: 'RUANDA',
    codeIso2: 'RW',
    codeIso3: 'RWA',
    codeNum3: '646',
  },
  {
    oid: 177,
    libelle: 'SENEGAL',
    libelleEnrichi: 'RÉPUBLIQUE DU SÉNÉGAL',
    ancienNom: '',
    codeIso2: 'SN',
    codeIso3: 'SEN',
    codeNum3: '686',
  },
  {
    oid: 178,
    libelle: 'SIERRA LEONE',
    libelleEnrichi: 'RÉPUBLIQUE DE SIERRA LEONE',
    ancienNom: '',
    codeIso2: 'SL',
    codeIso3: 'SLE',
    codeNum3: '694',
  },
  {
    oid: 179,
    libelle: 'SOUDAN',
    libelleEnrichi: 'RÉPUBLIQUE DU SOUDAN',
    ancienNom: 'ANGLO-EGYPTIEN',
    codeIso2: 'SD',
    codeIso3: 'SDN',
    codeNum3: '729',
  },
  {
    oid: 180,
    libelle: 'TCHAD',
    libelleEnrichi: 'RÉPUBLIQUE DU TCHAD',
    ancienNom: '',
    codeIso2: 'TD',
    codeIso3: 'TCD',
    codeNum3: '148',
  },
  {
    oid: 181,
    libelle: 'TOGO',
    libelleEnrichi: 'RÉPUBLIQUE TOGOLAISE',
    ancienNom: '',
    codeIso2: 'TG',
    codeIso3: 'TGO',
    codeNum3: '768',
  },
  {
    oid: 182,
    libelle: 'ZAMBIE',
    libelleEnrichi: 'RÉPUBLIQUE DE ZAMBIE',
    ancienNom: 'RHODESIE DU NORD',
    codeIso2: 'ZM',
    codeIso3: 'ZMB',
    codeNum3: '894',
  },
  {
    oid: 183,
    libelle: 'BOTSWANA',
    libelleEnrichi: 'RÉPUBLIQUE DU BOTSWANA',
    ancienNom: 'BECHUANALAND',
    codeIso2: 'BW',
    codeIso3: 'BWA',
    codeNum3: '72',
  },
  {
    oid: 184,
    libelle: 'LESOTHO',
    libelleEnrichi: 'ROYAUME DU LESOTHO',
    ancienNom: 'BASUTOLAND',
    codeIso2: 'LS',
    codeIso3: 'LSO',
    codeNum3: '426',
  },
  {
    oid: 185,
    libelle: 'SOUDAN DU SUD',
    libelleEnrichi: 'RÉPUBLIQUE DU SOUDAN DU SUD',
    ancienNom: '',
    codeIso2: 'SS',
    codeIso3: 'SSD',
    codeNum3: '728',
  },
  {
    oid: 186,
    libelle: 'MAROC',
    libelleEnrichi: 'ROYAUME DU MAROC',
    ancienNom: '',
    codeIso2: 'MA',
    codeIso3: 'MAR',
    codeNum3: '504',
  },
  {
    oid: 187,
    libelle: 'TUNISIE',
    libelleEnrichi: 'RÉPUBLIQUE TUNISIENNE',
    ancienNom: '',
    codeIso2: 'TN',
    codeIso3: 'TUN',
    codeNum3: '788',
  },
  {
    oid: 188,
    libelle: 'ALGERIE',
    libelleEnrichi: 'RÉPUBLIQUE ALGÉRIENNE DÉMOCRATIQUE ET POPULAIRE',
    ancienNom: '',
    codeIso2: 'DZ',
    codeIso3: 'DZA',
    codeNum3: '12',
  },
  {
    oid: 189,
    libelle: 'SAHARA OCCIDENTAL',
    libelleEnrichi: 'SAHARA OCCIDENTAL',
    ancienNom: '',
    codeIso2: 'EH',
    codeIso3: 'ESH',
    codeNum3: '732',
  },
  {
    oid: 190,
    libelle: 'MAURICE',
    libelleEnrichi: 'RÉPUBLIQUE DE MAURICE',
    ancienNom: '',
    codeIso2: 'MU',
    codeIso3: 'MUS',
    codeNum3: '480',
  },
  {
    oid: 191,
    libelle: 'SWAZILAND',
    libelleEnrichi: 'ROYAUME DU SWAZILAND',
    ancienNom: '',
    codeIso2: 'SZ',
    codeIso3: 'SWZ',
    codeNum3: '748',
  },
  {
    oid: 192,
    libelle: 'GUINEE-BISSAU',
    libelleEnrichi: 'RÉPUBLIQUE DE GUINÉE-BISSAU',
    ancienNom: 'GUINEE PORTUGAISE',
    codeIso2: 'GW',
    codeIso3: 'GNB',
    codeNum3: '624',
  },
  {
    oid: 193,
    libelle: 'MOZAMBIQUE',
    libelleEnrichi: 'RÉPUBLIQUE DU MOZAMBIQUE',
    ancienNom: '',
    codeIso2: 'MZ',
    codeIso3: 'MOZ',
    codeNum3: '508',
  },
  {
    oid: 194,
    libelle: 'SAO TOME-ET-PRINCIPE',
    libelleEnrichi: 'RÉPUBLIQUE DÉMOCRATIQUE DE SAO TOMÉ-ET-PRINCIPE',
    ancienNom: '',
    codeIso2: 'ST',
    codeIso3: 'STP',
    codeNum3: '678',
  },
  {
    oid: 195,
    libelle: 'ANGOLA',
    libelleEnrichi: "RÉPUBLIQUE D'ANGOLA",
    ancienNom: '',
    codeIso2: 'AO',
    codeIso3: 'AGO',
    codeNum3: '24',
  },
  {
    oid: 196,
    libelle: 'CAP-VERT',
    libelleEnrichi: 'RÉPUBLIQUE DU CAP-VERT',
    ancienNom: '',
    codeIso2: 'CV',
    codeIso3: 'CPV',
    codeNum3: '132',
  },
  {
    oid: 197,
    libelle: 'COMORES',
    libelleEnrichi: 'RÉPUBLIQUE FÉDÉRALE ISLAMIQUE DES COMORES',
    ancienNom: '',
    codeIso2: 'KM',
    codeIso3: 'COM',
    codeNum3: '174',
  },
  {
    oid: 198,
    libelle: 'SEYCHELLES',
    libelleEnrichi: 'RÉPUBLIQUE DES SEYCHELLES',
    ancienNom: '',
    codeIso2: 'SC',
    codeIso3: 'SYC',
    codeNum3: '690',
  },
  {
    oid: 199,
    libelle: 'DJIBOUTI',
    libelleEnrichi: 'RÉPUBLIQUE DE DJIBOUTI',
    ancienNom: 'T.F. AFARS ET ISSAS',
    codeIso2: 'DJ',
    codeIso3: 'DJI',
    codeNum3: '262',
  },
  {
    oid: 200,
    libelle: 'CANADA',
    libelleEnrichi: 'CANADA',
    ancienNom: '',
    codeIso2: 'CA',
    codeIso3: 'CAN',
    codeNum3: '124',
  },
  {
    oid: 201,
    libelle: 'TERRE-NEUVE',
    libelleEnrichi: 'TERRE-NEUVE',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 202,
    libelle: 'LABRADOR',
    libelleEnrichi: 'LABRADOR',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 203,
    libelle: 'ALASKA',
    libelleEnrichi: 'ALASKA',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 204,
    libelle: 'ETATS-UNIS',
    libelleEnrichi: "ÉTATS-UNIS D'AMÉRIQUE",
    ancienNom: '',
    codeIso2: 'US',
    codeIso3: 'USA',
    codeNum3: '840',
  },
  {
    oid: 205,
    libelle: 'MEXIQUE',
    libelleEnrichi: 'ÉTATS-UNIS DU MEXIQUE',
    ancienNom: '',
    codeIso2: 'MX',
    codeIso3: 'MEX',
    codeNum3: '484',
  },
  {
    oid: 206,
    libelle: 'COSTA RICA',
    libelleEnrichi: 'RÉPUBLIQUE DU COSTA RICA',
    ancienNom: '',
    codeIso2: 'CR',
    codeIso3: 'CRI',
    codeNum3: '188',
  },
  {
    oid: 207,
    libelle: 'CUBA',
    libelleEnrichi: 'RÉPUBLIQUE DE CUBA',
    ancienNom: '',
    codeIso2: 'CU',
    codeIso3: 'CUB',
    codeNum3: '192',
  },
  {
    oid: 208,
    libelle: 'DOMINICAINE (REPUBLIQUE)',
    libelleEnrichi: 'RÉPUBLIQUE DOMINICAINE',
    ancienNom: 'SAINT-DOMINGUE',
    codeIso2: 'DO',
    codeIso3: 'DOM',
    codeNum3: '214',
  },
  {
    oid: 209,
    libelle: 'GUATEMALA',
    libelleEnrichi: 'RÉPUBLIQUE DU GUATEMALA',
    ancienNom: '',
    codeIso2: 'GT',
    codeIso3: 'GTM',
    codeNum3: '320',
  },
  {
    oid: 210,
    libelle: 'HAITI',
    libelleEnrichi: "RÉPUBLIQUE D'HAÝTI",
    ancienNom: '',
    codeIso2: 'HT',
    codeIso3: 'HTI',
    codeNum3: '332',
  },
  {
    oid: 211,
    libelle: 'HONDURAS',
    libelleEnrichi: 'RÉPUBLIQUE DU HONDURAS',
    ancienNom: '',
    codeIso2: 'HN',
    codeIso3: 'HND',
    codeNum3: '340',
  },
  {
    oid: 212,
    libelle: 'NICARAGUA',
    libelleEnrichi: 'RÉPUBLIQUE DU NICARAGUA',
    ancienNom: '',
    codeIso2: 'NI',
    codeIso3: 'NIC',
    codeNum3: '558',
  },
  {
    oid: 213,
    libelle: 'PANAMA',
    libelleEnrichi: 'RÉPUBLIQUE DU PANAMA',
    ancienNom: '',
    codeIso2: 'PA',
    codeIso3: 'PAN',
    codeNum3: '591',
  },
  {
    oid: 214,
    libelle: 'EL SALVADOR',
    libelleEnrichi: 'RÉPUBLIQUE DU SALVADOR',
    ancienNom: '',
    codeIso2: 'SV',
    codeIso3: 'SLV',
    codeNum3: '222',
  },
  {
    oid: 215,
    libelle: 'ARGENTINE',
    libelleEnrichi: 'RÉPUBLIQUE ARGENTINE',
    ancienNom: '',
    codeIso2: 'AR',
    codeIso3: 'ARG',
    codeNum3: '32',
  },
  {
    oid: 216,
    libelle: 'BRESIL',
    libelleEnrichi: 'RÉPUBLIQUE FÉDÉRATIVE DU BRÉSIL',
    ancienNom: '',
    codeIso2: 'BR',
    codeIso3: 'BRA',
    codeNum3: '76',
  },
  {
    oid: 217,
    libelle: 'CHILI',
    libelleEnrichi: 'RÉPUBLIQUE DU CHILI',
    ancienNom: '',
    codeIso2: 'CL',
    codeIso3: 'CHL',
    codeNum3: '152',
  },
  {
    oid: 218,
    libelle: 'BOLIVIE',
    libelleEnrichi: 'ÉTAT PLURINATIONAL DE BOLIVIE',
    ancienNom: 'REPUBLIQUE DE BOLIVIE',
    codeIso2: 'BO',
    codeIso3: 'BOL',
    codeNum3: '68',
  },
  {
    oid: 219,
    libelle: 'COLOMBIE',
    libelleEnrichi: 'RÉPUBLIQUE DE COLOMBIE',
    ancienNom: '',
    codeIso2: 'CO',
    codeIso3: 'COL',
    codeNum3: '170',
  },
  {
    oid: 220,
    libelle: 'EQUATEUR',
    libelleEnrichi: "RÉPUBLIQUE DE L'ÉQUATEUR",
    ancienNom: '',
    codeIso2: 'EC',
    codeIso3: 'ECU',
    codeNum3: '218',
  },
  {
    oid: 221,
    libelle: 'PARAGUAY',
    libelleEnrichi: 'RÉPUBLIQUE DU PARAGUAY',
    ancienNom: '',
    codeIso2: 'PY',
    codeIso3: 'PRY',
    codeNum3: '600',
  },
  {
    oid: 222,
    libelle: 'PEROU',
    libelleEnrichi: 'RÉPUBLIQUE DU PÉROU',
    ancienNom: '',
    codeIso2: 'PE',
    codeIso3: 'PER',
    codeNum3: '604',
  },
  {
    oid: 223,
    libelle: 'URUGUAY',
    libelleEnrichi: "RÉPUBLIQUE ORIENTALE DE L'URUGUAY",
    ancienNom: '',
    codeIso2: 'UY',
    codeIso3: 'URY',
    codeNum3: '858',
  },
  {
    oid: 224,
    libelle: 'VENEZUELA',
    libelleEnrichi: 'RÉPUBLIQUE BOLIVARIENNE DU VENEZUELA',
    ancienNom: 'REPUBLIQUE DU VENEZUELA',
    codeIso2: 'VE',
    codeIso3: 'VEN',
    codeNum3: '862',
  },
  {
    oid: 225,
    libelle: 'BERMUDES',
    libelleEnrichi: 'BERMUDES',
    ancienNom: '',
    codeIso2: 'BM',
    codeIso3: 'BMU',
    codeNum3: '60',
  },
  {
    oid: 226,
    libelle: 'ANGUILLA',
    libelleEnrichi: 'ANGUILLA',
    ancienNom: '',
    codeIso2: 'AI',
    codeIso3: 'AIA',
    codeNum3: '660',
  },
  {
    oid: 227,
    libelle: 'CAIMANES (ILES)',
    libelleEnrichi: 'CAÝMANES (ÎLES)',
    ancienNom: '',
    codeIso2: 'KY',
    codeIso3: 'CYM',
    codeNum3: '136',
  },
  {
    oid: 228,
    libelle: 'VIERGES BRITANNIQUES (ILES)',
    libelleEnrichi: 'VIERGES BRITANNIQUES (ÎLES)',
    ancienNom: '',
    codeIso2: 'VG',
    codeIso3: 'VGB',
    codeNum3: '92',
  },
  {
    oid: 229,
    libelle: 'TURKS ET CAIQUES (ILES)',
    libelleEnrichi: 'TURKS ET CAÝQUES (ÎLES)',
    ancienNom: '',
    codeIso2: 'TC',
    codeIso3: 'TCA',
    codeNum3: '796',
  },
  {
    oid: 230,
    libelle: 'TERRITOIRES DU ROYAUME-UNI AUX ANTILLES',
    libelleEnrichi: 'TERRITOIRES DU ROYAUME-UNI AUX ANTILLES',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 231,
    libelle: 'MONTSERRAT',
    libelleEnrichi: 'MONTSERRAT',
    ancienNom: '',
    codeIso2: 'MS',
    codeIso3: 'MSR',
    codeNum3: '500',
  },
  {
    oid: 232,
    libelle: 'JAMAIQUE',
    libelleEnrichi: 'JAMAÝQUE',
    ancienNom: '',
    codeIso2: 'JM',
    codeIso3: 'JAM',
    codeNum3: '388',
  },
  {
    oid: 233,
    libelle: 'MALOUINES, OU FALKLAND (ILES)',
    libelleEnrichi: 'MALOUINES, OU FALKLAND (ÎLES)',
    ancienNom: '',
    codeIso2: 'FK',
    codeIso3: 'FLK',
    codeNum3: '238',
  },
  {
    oid: 234,
    libelle: "TERR. DU ROYAUME-UNI DANS L'ATLANTIQUE SUD",
    libelleEnrichi: "TERR. DU ROYAUME-UNI DANS L'ATLANTIQUE_SUD",
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 235,
    libelle: 'GEORGIE DU SUD ET LES ILES SANDWICH DU SUD',
    libelleEnrichi: 'GÉORGIE DU SUD ET LES ÎLES SANDWICH DU SUD',
    ancienNom: '',
    codeIso2: 'GS',
    codeIso3: 'SGS',
    codeNum3: '239',
  },
  {
    oid: 236,
    libelle: 'GUYANA',
    libelleEnrichi: 'RÉPUBLIQUE COOPÉRATIVE DE GUYANA',
    ancienNom: 'GUYANE BRITANNIQUE',
    codeIso2: 'GY',
    codeIso3: 'GUY',
    codeNum3: '328',
  },
  {
    oid: 237,
    libelle: 'BELIZE',
    libelleEnrichi: 'BELIZE',
    ancienNom: 'HONDURAS BRITANNIQUE',
    codeIso2: 'BZ',
    codeIso3: 'BLZ',
    codeNum3: '84',
  },
  {
    oid: 238,
    libelle: 'GROENLAND',
    libelleEnrichi: 'GROENLAND',
    ancienNom: '',
    codeIso2: 'GL',
    codeIso3: 'GRL',
    codeNum3: '304',
  },
  {
    oid: 239,
    libelle: 'ANTILLES NEERLANDAISES',
    libelleEnrichi: 'ANTILLES NÉERLANDAISES',
    ancienNom: '',
    codeIso2: 'AN',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 240,
    libelle: 'PORTO RICO',
    libelleEnrichi: 'PORTO RICO',
    ancienNom: '',
    codeIso2: 'PR',
    codeIso3: 'PRI',
    codeNum3: '630',
  },
  {
    oid: 241,
    libelle: "TERR. DES ETATS-UNIS D'AMERIQUE EN AMERIQUE",
    libelleEnrichi: "TERR. DES ÉTATS-UNIS D'AMÉRIQUE EN AMÉRIQUE",
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 242,
    libelle: 'VIERGES DES ETATS-UNIS (ILES)',
    libelleEnrichi: 'VIERGES DES ÉTATS-UNIS (ÎLES)',
    ancienNom: '',
    codeIso2: 'VI',
    codeIso3: 'VIR',
    codeNum3: '850',
  },
  {
    oid: 243,
    libelle: 'TRINITE-ET-TOBAGO',
    libelleEnrichi: 'RÉPUBLIQUE DE TRINITÉ-ET-TOBAGO',
    ancienNom: '',
    codeIso2: 'TT',
    codeIso3: 'TTO',
    codeNum3: '780',
  },
  {
    oid: 244,
    libelle: 'BARBADE',
    libelleEnrichi: 'BARBADE',
    ancienNom: '',
    codeIso2: 'BB',
    codeIso3: 'BRB',
    codeNum3: '52',
  },
  {
    oid: 245,
    libelle: 'GRENADE',
    libelleEnrichi: 'GRENADE',
    ancienNom: '',
    codeIso2: 'GD',
    codeIso3: 'GRD',
    codeNum3: '308',
  },
  {
    oid: 246,
    libelle: 'BAHAMAS',
    libelleEnrichi: 'COMMONWEALTH DES BAHAMAS',
    ancienNom: 'ILES LUCAYES',
    codeIso2: 'BS',
    codeIso3: 'BHS',
    codeNum3: '44',
  },
  {
    oid: 247,
    libelle: 'SURINAME',
    libelleEnrichi: 'RÉPUBLIQUE DU SURINAME',
    ancienNom: 'GUYANE NEERLANDAISE',
    codeIso2: 'SR',
    codeIso3: 'SUR',
    codeNum3: '740',
  },
  {
    oid: 248,
    libelle: 'DOMINIQUE',
    libelleEnrichi: 'COMMONWEALTH DE DOMINIQUE',
    ancienNom: '',
    codeIso2: 'DM',
    codeIso3: 'DMA',
    codeNum3: '212',
  },
  {
    oid: 249,
    libelle: 'SAINTE-LUCIE',
    libelleEnrichi: 'SAINTE-LUCIE',
    ancienNom: '',
    codeIso2: 'LC',
    codeIso3: 'LCA',
    codeNum3: '662',
  },
  {
    oid: 250,
    libelle: 'SAINT-VINCENT-ET-LES GRENADINES',
    libelleEnrichi: 'SAINT-VINCENT-ET-LES GRENADINES',
    ancienNom: '',
    codeIso2: 'VC',
    codeIso3: 'VCT',
    codeNum3: '670',
  },
  {
    oid: 251,
    libelle: 'ANTIGUA-ET-BARBUDA',
    libelleEnrichi: 'ANTIGUA-ET-BARBUDA',
    ancienNom: '',
    codeIso2: 'AG',
    codeIso3: 'ATG',
    codeNum3: '28',
  },
  {
    oid: 252,
    libelle: 'SAINT-CHRISTOPHE-ET-NIEVES',
    libelleEnrichi: 'FÉDÉRATION DE SAINT-CHRISTOPHE-ET-NIÉVÈS',
    ancienNom: '',
    codeIso2: 'KN',
    codeIso3: 'KNA',
    codeNum3: '659',
  },
  {
    oid: 253,
    libelle: 'BONAIRE, SAINT EUSTACHE ET SABA',
    libelleEnrichi: 'BONAIRE, SAINT EUSTACHE ET SABA',
    ancienNom: '',
    codeIso2: 'BQ',
    codeIso3: 'BES',
    codeNum3: '535',
  },
  {
    oid: 254,
    libelle: 'CURAÇAO',
    libelleEnrichi: 'CURAÇAO',
    ancienNom: '',
    codeIso2: 'CW',
    codeIso3: 'CUW',
    codeNum3: '531',
  },
  {
    oid: 255,
    libelle: 'SAINT-MARTIN (PARTIE NEERLANDAISE)',
    libelleEnrichi: 'SAINT-MARTIN (PARTIE NÉERLANDAISE)',
    ancienNom: '',
    codeIso2: 'SX',
    codeIso3: 'SXM',
    codeNum3: '534',
  },
  {
    oid: 256,
    libelle: 'CHRISTMAS (ILE)',
    libelleEnrichi: 'CHRISTMAS (ÎLE)',
    ancienNom: '',
    codeIso2: 'CX',
    codeIso3: 'CXR',
    codeNum3: '162',
  },
  {
    oid: 257,
    libelle: 'AUSTRALIE',
    libelleEnrichi: 'AUSTRALIE',
    ancienNom: '',
    codeIso2: 'AU',
    codeIso3: 'AUS',
    codeNum3: '36',
  },
  {
    oid: 258,
    libelle: 'COCOS ou KEELING (ILES)',
    libelleEnrichi: 'COCOS OU KEELING (ÎLES)',
    ancienNom: '',
    codeIso2: 'CC',
    codeIso3: 'CCK',
    codeNum3: '166',
  },
  {
    oid: 259,
    libelle: 'HEARD ET MACDONALD (ILES)',
    libelleEnrichi: 'HEARD ET MACDONALD (ÎLES)',
    ancienNom: '',
    codeIso2: 'HM',
    codeIso3: 'HMD',
    codeNum3: '334',
  },
  {
    oid: 260,
    libelle: 'NORFOLK (ILE)',
    libelleEnrichi: 'NORFOLK (ÎLE)',
    ancienNom: '',
    codeIso2: 'NF',
    codeIso3: 'NFK',
    codeNum3: '574',
  },
  {
    oid: 261,
    libelle: 'COOK (ILES)',
    libelleEnrichi: 'COOK (ÎLES)',
    ancienNom: '',
    codeIso2: 'CK',
    codeIso3: 'COK',
    codeNum3: '184',
  },
  {
    oid: 262,
    libelle: 'TOKELAU',
    libelleEnrichi: 'TOKELAU',
    ancienNom: '',
    codeIso2: 'TK',
    codeIso3: 'TKL',
    codeNum3: '772',
  },
  {
    oid: 263,
    libelle: 'NIUE',
    libelleEnrichi: 'NIUE',
    ancienNom: '',
    codeIso2: 'NU',
    codeIso3: 'NIU',
    codeNum3: '570',
  },
  {
    oid: 264,
    libelle: 'NOUVELLE-ZELANDE',
    libelleEnrichi: 'NOUVELLE-ZÉLANDE',
    ancienNom: '',
    codeIso2: 'NZ',
    codeIso3: 'NZL',
    codeNum3: '554',
  },
  {
    oid: 265,
    libelle: 'PITCAIRN (ILE)',
    libelleEnrichi: 'PITCAIRN (ÎLE)',
    ancienNom: '',
    codeIso2: 'PN',
    codeIso3: 'PCN',
    codeNum3: '612',
  },
  {
    oid: 266,
    libelle: 'HAWAII (ILES)',
    libelleEnrichi: 'HAWAII (ÎLES)',
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 267,
    libelle: 'MARIANNES DU NORD (ILES)',
    libelleEnrichi: 'MARIANNES DU NORD (ÎLES)',
    ancienNom: '',
    codeIso2: 'MP',
    codeIso3: 'MNP',
    codeNum3: '580',
  },
  {
    oid: 268,
    libelle: 'GUAM',
    libelleEnrichi: 'GUAM',
    ancienNom: '',
    codeIso2: 'GU',
    codeIso3: 'GUM',
    codeNum3: '316',
  },
  {
    oid: 269,
    libelle: 'SAMOA AMERICAINES',
    libelleEnrichi: 'SAMOA AMÉRICAINES',
    ancienNom: '',
    codeIso2: 'AS',
    codeIso3: 'ASM',
    codeNum3: '16',
  },
  {
    oid: 270,
    libelle: "TERR. DES ETATS-UNIS D'AMERIQUE EN OCEANIE",
    libelleEnrichi: "TERR. DES ÉTATS-UNIS D'AMÉRIQUE EN OCÉANIE",
    ancienNom: '',
    codeIso2: '',
    codeIso3: '',
    codeNum3: '',
  },
  {
    oid: 271,
    libelle: 'SAMOA OCCIDENTALES',
    libelleEnrichi: 'ÉTAT INDÉPENDANT DES SAMOA OCCIDENTALES',
    ancienNom: '',
    codeIso2: 'WS',
    codeIso3: 'WSM',
    codeNum3: '882',
  },
  {
    oid: 272,
    libelle: 'NAURU',
    libelleEnrichi: 'RÉPUBLIQUE DE NAURU',
    ancienNom: '',
    codeIso2: 'NR',
    codeIso3: 'NRU',
    codeNum3: '520',
  },
  {
    oid: 273,
    libelle: 'FIDJI',
    libelleEnrichi: 'RÉPUBLIQUE DES FIDJI',
    ancienNom: '',
    codeIso2: 'FJ',
    codeIso3: 'FJI',
    codeNum3: '242',
  },
  {
    oid: 274,
    libelle: 'TONGA',
    libelleEnrichi: 'ROYAUME DES TONGA',
    ancienNom: '',
    codeIso2: 'TO',
    codeIso3: 'TON',
    codeNum3: '776',
  },
  {
    oid: 275,
    libelle: 'PAPOUASIE-NOUVELLE-GUINEE',
    libelleEnrichi: 'PAPOUASIE-NOUVELLE-GUINÉE',
    ancienNom: '',
    codeIso2: 'PG',
    codeIso3: 'PNG',
    codeNum3: '598',
  },
  {
    oid: 276,
    libelle: 'TUVALU',
    libelleEnrichi: 'TUVALU',
    ancienNom: 'ILES ELLICE',
    codeIso2: 'TV',
    codeIso3: 'TUV',
    codeNum3: '798',
  },
  {
    oid: 277,
    libelle: 'SALOMON (ILES)',
    libelleEnrichi: 'ÎLES SALOMON',
    ancienNom: 'ILES SALOMON DU SUD',
    codeIso2: 'SB',
    codeIso3: 'SLB',
    codeNum3: '90',
  },
  {
    oid: 278,
    libelle: 'KIRIBATI',
    libelleEnrichi: 'RÉPUBLIQUE DE KIRIBATI',
    ancienNom: 'ILES GILBERT',
    codeIso2: 'KI',
    codeIso3: 'KIR',
    codeNum3: '296',
  },
  {
    oid: 279,
    libelle: 'VANUATU',
    libelleEnrichi: 'RÉPUBLIQUE DE VANUATU',
    ancienNom: 'NOUVELLES-HEBRIDES',
    codeIso2: 'VU',
    codeIso3: 'VUT',
    codeNum3: '548',
  },
  {
    oid: 280,
    libelle: 'MARSHALL (ILES)',
    libelleEnrichi: 'RÉPUBLIQUE DES ÎLES MARSHALL',
    ancienNom: '',
    codeIso2: 'MH',
    codeIso3: 'MHL',
    codeNum3: '584',
  },
  {
    oid: 281,
    libelle: 'MICRONESIE (ETATS FEDERES DE)',
    libelleEnrichi: 'ÉTATS FÉDÉRÉS DE MICRONÉSIE',
    ancienNom: '',
    codeIso2: 'FM',
    codeIso3: 'FSM',
    codeNum3: '583',
  },
  {
    oid: 282,
    libelle: 'PALAOS (ILES)',
    libelleEnrichi: 'RÉPUBLIQUE DES ÎLES PALAOS',
    ancienNom: 'BELAU',
    codeIso2: 'PW',
    codeIso3: 'PLW',
    codeNum3: '585',
  },
];
