export const types = {
  GET_ACCOUNT_INFO: 'GET_ACCOUNT_INFO',
  GET_FRESH_ACC_INFO: 'GET_FRESH_ACC_INFO',
  GET_USER_INFO: 'GET_USER_INFO',
  SET_USER_INFO: 'SET_USER_INFO',
  SET_USER_PRODUCTS: 'SET_USER_PRODUCTS',
  SET_USER_CARD_INFO: 'SET_USER_CARD_INFO',
  SET_USER_IS_AUTH: 'SET_USER_IS_AUTH',
  SET_USER_LOGGEDIN: 'SET_USER_LOGGEDIN',
  LOGOUT_USER: 'LOGOUT_USER',
};

export const getAccountInfo = (currentSession, selectedCenter) => ({
  type: types.GET_ACCOUNT_INFO,
  payload: { currentSession, selectedCenter },
});

export const getUserInfo = (currentSession, selectedCenter) => ({
  type: types.GET_USER_INFO,
  payload: { currentSession, selectedCenter },
});

export const getFreshAccountInfo = (currentSession, selectedCenter) => ({
  type: types.GET_FRESH_ACC_INFO,
  payload: { currentSession, selectedCenter },
});

export const setUserAuth = (val) => ({
  type: types.SET_USER_IS_AUTH,
  payload: { val },
});

export const setUserLoggedIn = (user) => ({
  type: types.SET_USER_LOGGEDIN,
  payload: { user },
});

export const logoutUser = (centerName, currentSession) => ({
  type: types.LOGOUT_USER,
  payload: { centerName, currentSession },
});

export const setUserInfo = (userInfo) => ({
  type: types.SET_USER_INFO,
  payload: { userInfo },
});

export const setUserProducts = (userProducts) => ({
  type: types.SET_USER_PRODUCTS,
  payload: { userProducts },
});

export const setUserCardInfo = (userCardInfo) => ({
  type: types.SET_USER_CARD_INFO,
  payload: { userCardInfo },
});
