export const types = {
  PAYMENT_STATUS_START: 'PAYMENT_STATUS_START',
  REQUEST_PAYMENT_STATUS: 'REQUEST_PAYMENT_STATUS',
  RECEIVE_PAYMENT_STATUS: 'RECEIVE_PAYMENT_STATUS',
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  PAYMENT_FAILURE: 'PAYMENT_FAILURE',
};

export const startPaymentStatus = () => ({
  type: types.PAYMENT_STATUS_START,
  payload: {},
});

export const requestPaymentStatus = () => ({
  type: types.REQUEST_PAYMENT_STATUS,
  payload: {},
});

export const receivePaymentStatus = (payment) => ({
  type: types.RECEIVE_PAYMENT_STATUS,
  payload: {
    webRef: payment.data.responseData.PaymentCode,
    orderId: payment.data.responseData.invoice_id,
    result: payment.data.responseData.result,
    status: payment.data.status,
  },
});

export const paymentFailure = () => ({
  type: types.PAYMENT_FAILURE,
  payload: {},
});
