import axios from 'axios';
import Cookies from 'universal-cookie';
import qs from 'qs';
import { navigate } from 'gatsby';
import { centerCookieIds } from 'utils/constants';
import store from 'redux/store';
import { logoutUser } from 'redux/actions/auth';

const cookies = new Cookies();

const API = axios.create({
  baseURL: 'https://preprod.caliceo.com/api',
  headers: {
    Authorization: 'Basic Y2FsaWNlb19hY2Nlc3M6Y2FsaWNlb19kZXY=',
  },
});

// instance that doesn't use the interceptors
export const APIWOI = axios.create({
  baseURL: 'https://preprod.caliceo.com/api',
  headers: {
    Authorization: 'Basic Y2FsaWNlb19hY2Nlc3M6Y2FsaWNlb19kZXY=',
  },
});

export const getCookie = axios.create({
  baseURL: 'https://preprod.caliceo.com/api/odfoufstfttjp',
  headers: {
    Authorization: 'Basic Y2FsaWNlb19hY2Nlc3M6Y2FsaWNlb19kZXY=',
  },
});

API.interceptors.response.use((res) => {
  const { centerId } = res.config;
  if (res.data.status === 2) {
    if (localStorage.getItem(centerCookieIds[centerId].cookieName)) {
      store.dispatch(logoutUser(centerId, cookies.get(centerCookieIds[centerId]?.cookieName)));
      const newCookie = cookies.get(centerCookieIds[centerId].cookieName);
      const originalConfig = qs.parse(res.config.data);
      originalConfig.session = newCookie;
      res.config.data = qs.stringify(originalConfig);
      return API.request(res.config);
    }
    return getCookie.get(`?cid=${centerCookieIds[centerId].cid}`).then(
      (cookieRes) => {
        cookies.set(
          `${centerCookieIds[centerId].cookieName}`,
          cookieRes.data.responseData.userContent,
          { path: '/' },
        );
        const originalConfig = qs.parse(res.config.data);
        originalConfig.session = cookieRes.data.responseData.userContent;
        res.config.data = qs.stringify(originalConfig);
        return API.request(res.config);
      },
    );
  }
  if (!res.data.responseData && res.data.status === 0) {
    return getCookie.get(`?cid=${centerCookieIds[centerId].cid}`).then(
      (cookieRes) => {
        cookies.set(
          `${centerCookieIds[centerId].cookieName}`,
          cookieRes.data.responseData.userContent,
          { path: '/' },
        );
        const originalConfig = qs.parse(res.config.data);
        originalConfig.session = cookieRes.data.responseData.userContent;
        res.config.data = qs.stringify(originalConfig);
        return API.request(res.config);
      },
    );
  }
  if (res.data.status === 5) {
    store.dispatch(logoutUser(centerId, cookies.get(centerCookieIds[centerId]?.cookieName)));
  }
  return res;
}, (error) => Promise.reject(error));

export default API;
