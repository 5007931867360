import { all, fork } from 'redux-saga/effects';

import authSaga from 'redux/sagas/auth';
import paymentSaga from 'redux/sagas/payment';

export default function * rootSaga() {
  yield all([
    fork(authSaga),
    fork(paymentSaga),
  ]);
}
