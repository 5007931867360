export const types = {
  SET_CART: 'SET_CART',
  SET_PRODUCT_AS_GIFT: 'SET_PRODUCT_AS_GIFT',
  REMOVE_PRODUCT_AS_GIFT: 'REMOVE_PRODUCT_AS_GIFT',
  SET_PRODUCT_AS_COFFRET: 'SET_PRODUCT_AS_COFFRET',
  REMOVE_PRODUCT_AS_COFFRET: 'REMOVE_PRODUCT_AS_COFFRET',
  ADD_LAST_ITEM_URL: 'ADD_LAST_ITEM_URL',
  REMOVE_LAST_ITEM_URL: 'REMOVE_LAST_ITEM_URL',
  REMOVE_ALL_GIFT_PRODUCTS: 'REMOVE_ALL_GIFT_PRODUCTS',
  SET_CODE_CADEAU: 'SET_CODE_CADEAU',
  REMOVE_CODE_CADEAU: 'REMOVE_CODE_CADEAU',
};

export const setCart = (cart) => ({
  type: types.SET_CART,
  payload: { cart },
});

export const setProductAsGift = (product) => ({
  type: types.SET_PRODUCT_AS_GIFT,
  payload: { product },
});

export const removeProductAsGift = (product) => ({
  type: types.REMOVE_PRODUCT_AS_GIFT,
  payload: { product },
});

export const setProductAsCoffret = (product) => ({
  type: types.SET_PRODUCT_AS_COFFRET,
  payload: { product },
});

export const removeProductAsCoffret = (product) => ({
  type: types.REMOVE_PRODUCT_AS_COFFRET,
  payload: { product },
});

export const setCodeCadeau = (code) => ({
  type: types.SET_CODE_CADEAU,
  payload: { code },
});

export const removeCodeCadeau = (code) => ({
  type: types.REMOVE_CODE_CADEAU,
  payload: { code },
});

export const addLastItemUrl = (url) => ({
  type: types.ADD_LAST_ITEM_URL,
  payload: { url },
});

export const removeLastItemUrl = () => ({
  type: types.REMOVE_LAST_ITEM_URL,
  payload: {},
});

export const removeAllGiftItems = () => ({
  type: types.REMOVE_ALL_GIFT_PRODUCTS,
  payload: {},
});
