import { types } from '../actions/auth';

const defaultState = {
  user: {
    products: {},
    infoCard: {},
  },
  isAuthenticated: false,
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_USER_LOGGEDIN:
      return {
        ...state,
        isAuthenticated: true,
        user: {
          ...state.user, ...action.payload.user,
        },
      };
    case types.SET_USER_INFO: {
      return {
        ...state,
        isAuthenticated: true,
        user: {
          ...state.user, ...action.payload.userInfo,
        },
      };
    }
    case types.SET_USER_PRODUCTS: {
      return {
        ...state,
        user: {
          ...state.user,
          products: action.payload.userProducts,
        },
      };
    }
    case types.SET_USER_CARD_INFO: {
      return {
        ...state,
        user: {
          ...state.user,
          infoCard: action.payload.userCardInfo,
        },
      };
    }
    default:
      return state;
  }
};

export default authReducer;
