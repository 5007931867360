import { all, takeLatest, put } from 'redux-saga/effects';
import qs from 'qs';
import Cookies from 'universal-cookie';
import { navigate } from 'gatsby';
import API, { getCookie } from 'api';
import { centerCookieIds } from 'utils/constants';

import {
  types,
  setUserInfo,
  setUserProducts,
  logoutUser,
  setUserCardInfo,
} from 'redux/actions/auth';

const cookies = new Cookies();

function * handleGetAccountInfo(action) {
  const values = {
    session: action.payload.currentSession,
    center: centerCookieIds[action.payload.selectedCenter].cid,
    cid:centerCookieIds[action.payload.selectedCenter].cid,
  };
  try {
    const accountRes = yield API.post('/my-account', qs.stringify(values), {
      centerId: action.payload.selectedCenter,
    });
    const accountProductsRes = yield API.post(
      '/my-account/products',
      qs.stringify(values),
      { centerId: action.payload.selectedCenter },
    );
    const accountInfoCardRes = yield API.post(
      '/my-account/card',
      qs.stringify(values),
      { centerId: action.payload.selectedCenter },
    );
    if (
      !accountRes.data.responseData
      || !accountProductsRes.data.responseData
      || !accountInfoCardRes.data.responseData
      || accountRes.data.responseData.errorMessage
    ) {
      if (
        localStorage.getItem(
          centerCookieIds[action.payload.selectedCenter].cookieName,
        )
      ) {
        yield put(
          logoutUser(
            action.payload.selectedCenter,
            action.payload.currentSession,
          ),
        );
      }
    } else {
      yield put(setUserInfo(accountRes.data.responseData.accountContent));
      yield put(
        setUserProducts(accountProductsRes.data.responseData.accountContent),
      );
      yield put(
        setUserCardInfo(accountInfoCardRes.data.responseData.accountContent),
      );
    }
  } catch (error) {
    //console.log(error);
  }
}

function * handleGetUserInfo(action) {
  const values = {
    session: action.payload.currentSession,
    center: centerCookieIds[action.payload.selectedCenter].cid,
  };
  try {
    const accountRes = yield API.post('/my-account', qs.stringify(values), {
      centerId: action.payload.selectedCenter,
    });

    if (
      !accountRes.data.responseData
      || accountRes.data.responseData.errorMessage
    ) {
      if (
        localStorage.getItem(
          centerCookieIds[action.payload.selectedCenter].cookieName,
        )
      ) {
        yield put(
          logoutUser(
            action.payload.selectedCenter,
            action.payload.currentSession,
          ),
        );
      }
    } else {
      yield put(setUserInfo(accountRes.data.responseData.accountContent));
    }
  } catch (error) {
    //console.log(error);
  }
}

// eslint-disable-next-line require-yield
function * handleLogout(action) {
  const {
    payload: { centerName, currentSession },
  } = action;

  API.post('/umphpv', qs.stringify({ session: currentSession })).then(() => {
    localStorage.removeItem(centerCookieIds[centerName]?.cookieName);
    localStorage.removeItem('userOid');
    localStorage.removeItem('webRef');
    getCookie
      .get(`?cid=${centerCookieIds[centerName].cid}`)
      .then((newCookieRes) => {
        cookies.set(
          `${centerCookieIds[centerName].cookieName}`,
          newCookieRes.data.responseData.userContent,
          { path: '/' },
        );
      });

    navigate('/login');
  });
}

function * handleGetFreshAccountInfo(action) {
  const values = {
    session: action.payload.currentSession,
    center: action.payload.selectedCenter,
  };
  try {
    const accountProductsRes = yield API.post(
      '/my-account/products',
      qs.stringify(values),
      { centerId: action.payload.selectedCenter },
    );
    const accountInfoCardRes = yield API.post(
      '/my-account/card',
      qs.stringify(values),
      { centerId: action.payload.selectedCenter },
    );
    yield put(
      setUserProducts(accountProductsRes.data.responseData.accountContent),
    );
    yield put(
      setUserCardInfo(accountInfoCardRes.data.responseData.accountContent),
    );
  } catch (e) {
    //console.log(e);
  }
}

export default function * authSaga() {
  yield all([
    takeLatest(types.GET_ACCOUNT_INFO, handleGetAccountInfo),
    takeLatest(types.LOGOUT_USER, handleLogout),
    takeLatest(types.GET_FRESH_ACC_INFO, handleGetFreshAccountInfo),
    takeLatest(types.GET_USER_INFO, handleGetUserInfo),
  ]);
}
